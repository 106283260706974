import React from "react";
import onboardingDemo from "../assets/videos/onboarding-demo.mp4";

const Testimonial = () => {
  

  return (
    <section className="testimonials-one">
      <div className="container">
        <div className="block-title text-center">
          <h2 className="block-title__title">
            We are not joking. <br /> It literally takes minutes to set everything up.
          </h2>
        </div>
        <div className="testimonials-one__carousel-outer">
          <div className="testimonials-one__carousel">
              <div className="item browser-mockup">
              <video muted autoPlay playsInline loop>
                      <source src={onboardingDemo} type="video/mp4">
                    </source>
                </video>
              </div>
          </div>
          
        </div>
      </div>
    </section>
  );
};
export default Testimonial;
