import React from "react";
import mocImage from "../assets/images/resources/restaurant-barcode-qr-code-menu.png";
import Texts from "../../../utils/lang-utils";
import urls from "../../../areas/main/assets/urls";

const AnswersSection = () => {
  return (
    <section className="cta-one">

      <div className="container">
        
        <div className="row">
          <div className="col-lg-6">
            <div className="cta-one__content">
              <div className="block-title text-left">
                <h2 className="block-title__title">
                 {Texts.ExplanationTitle}
                </h2>
                <h3>
                  {Texts.ExplanationSubtitle}
                </h3>
              </div>
              <div className="cta-two__text">
                <p dangerouslySetInnerHTML={{  __html: Texts.ExplanationDescription}} />
              </div>
              <a href={urls} className="thm-btn">
                <span>{Texts.GetStarted}</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <img src={mocImage} alt="restaurant barcode and qr code menu" style={{
                  marginTop: 40,
                  borderRadius: 20,
                  boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
            }} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AnswersSection;
